.stats {
    flex-wrap: wrap;

    &>* {
        display: block;
        flex: 1;
        width: 100px;

        &>* {
            padding: 8px !important;
        }
    }

    .stat-card {
        h4 {
            font-size: 0.9rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .stats {
        flex-wrap: wrap;

        &>* {
            display: block;
            flex: 1;
            width: 100%;

            &>* {
                padding: 8px !important;
            }
        }
    }

    .customers-stats{
        gap: 16px;
        
        & > * {
            width: 100% !important;
        }
    }
}