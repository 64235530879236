.flight-info {
    display: flex;
    gap: 5rem;
    align-items: center;

    &>.boardpass-icon i {
        font-size: 60px;
    }

    &>* {
        flex: 1;
    }
}


@media screen and (max-width: 768px) {
    .flight-info{
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
    }
}

.airline-icon {
    height: 40px;
    width: auto;
}