@import './nav.scss';
@import '../../components/Snack/snack.scss';
@import './form.scss';
@import './material-custom.scss';

hr,
.hr {
    margin: 1rem 0;
    border: 0.5px solid #ddd;
}

.avatar-md {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}

// Эффект приближение при hover
.hover-scale-in {
    overflow: hidden;
}
.hover-scale:hover .hover-scale-in img {
    transform: scale(1.05)
}
.hover-scale-in img {
    transition: ease all .35s;
    transform: scale(1);
}