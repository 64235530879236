.logo{
  gap: 1rem;
  display: flex;
}
.logo a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-label {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .logo img {
    height: 30px;
  }
}
