.admin-layout main {
  display: flex;
}

.admin-layout nav {
  display: flex;
  flex-direction: column;
}

section {
  padding: 0;
}

#admin-main {
  display: grid;
  grid-template-columns: 200px 1fr;
}

// LEFT SIDEBAR
.left-sidebar {
  overflow: scroll;
  border-right: 1px solid #e5eaef;
  transition: 0.2s ease-in;
  height: 100%;
  z-index: 11;
  width: 300px;
  position: fixed;
  background-color: var(--background-color);

  &>.admin-logo {
    min-height: 70px;
    padding: 0 24px;
  }

  &>.sidebar-nav {
    overflow-y: auto;
    padding: 0 24px;
    height: calc(100vh - 80px);
    border-radius: 7px;
    margin-top: 24px;
  }

  .sidebar-link {
    display: flex;
    padding: 0.5rem 1.5rem;

    &.active {
      background-color: #5d87ff;
      color: #fff;

      svg {
        color: white;
      }
    }

    &:not(.active):hover {
      background-color: rgba(93, 135, 255, 0.2);
      color: #5d87ff;
    }
  }

  .sub-menu-wrapper{
    display: none;
  }  
  .sidebar-link.active + .sub-menu-wrapper{
    display: block;
  }  

  .sub-menu {
    display: flex;
    flex-direction: column;

    .active {
      background-color: rgba(93, 135, 255, 0.2);
      color: inherit;
    }
  }
}

.admin-nav>nav {
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &>a {
    gap: 5px;
    display: flex;
  }
}

.admin-content {
  flex: 1;
  padding: 24px;
  margin-left: 300px;
  position: relative;
}

@media screen and (max-width: 768px) {

  // Mobile
  #admin-main {
    display: block;

    .breadcrumb {
      margin-top: 30px;
    }
  }

  .admin-content {
    margin-left: 0;
    padding: 8px;
  }

  .left-sidebar {
    display: none;
  }

  .left-sidebar.active {
    display: block;
  }
}


.app-form {
  &>* {
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }
}