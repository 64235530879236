@import "./resets";
@import "./normalize";
@import "./theme.scss";

@import "./variables.scss";

@import "./background";
@import "./font-colors.scss";
@import "./flex";
@import "./text.scss";

// Helpers
@import "./helpers/index";

@import "./components";
@import "./adaptive.scss";

:root {
    background-color: var(--background-color);
    color: var(--text-color)
}

@media screen and (max-width: 768px) {
    .m-w-100 {
        width: 100% !important;
    }
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 20px;
}

.card-reset {
    &>div {
        border: 0px;
        box-shadow: none;
        padding: 0px;
    }
}
.d-block{
    display: block;
}