input {
  padding: 5px;
  color: inherit;
}

input::disabled {
  color: inherit;
}

.form-input {
  margin: 16px 0;
}

.form-input__label {
  margin-bottom: 4px;
  font-size: 14px;
  color: inherit;
}


.chip-label {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  border-radius: 16px;
  border: 1px solid #bdbdbd;
  padding-top: 4px;
  padding-bottom: 4px;
}

.chip-label:has(input:checked){ 
  background-color: var(--color-blue);
  color: white;
  border: 1px solid var(--color-blue);
}