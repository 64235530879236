#snackbar {
    min-width: 250px;
    background-color: var(--md-sys-color-inverse-surface, #333);
    color: var(--md-sys-color-inverse-on-surface, white);
    text-align: center;
    border-radius: 4px;
    padding: 12px 20px;
    position: fixed;
    z-index: 1000;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, -50%);
}

.close-button {
    cursor: pointer;
    position: absolute;
    right: 10px;
    font-size: 0.8rem;
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}