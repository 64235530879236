.phone-input {
    display: flex;

    input {
        width: 100%;
        padding: 18px !important;
        border: 1px solid #ccc;
        background-color: inherit;
    }

    input:focus {
        outline: none;
    }

    .phone-input__phone{
        width: 100%;
    }
}

.phone-selected {
    img {
        height: 15px;
        width: 22px;
        margin-right: 5px;
    }
}