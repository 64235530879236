@media screen and (max-width: 768px) {
    .m-text-center {
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

.flexw{    
    flex-wrap: wrap;
}